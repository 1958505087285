import React from 'react'

const BigButton = ({ text, href, className, innerClassName, onClick, target = '_self', largeText = false }) => {
  return <>
    <div onClick={onClick} className={`flex flex-wrap items-center ${className} !my-6`}>
      <a target={target}
          rel="noreferrer noopener"
          href={href}
          className={`normal-wolf-button w-full sm:w-auto text-center !font-poppins !font-extrabold !md:text-xl rounded-3xl px-6 py-4 !no-underline ${largeText ? '!text-2xl' : '!text-sm'} ${innerClassName}`}>
        {text}
      </a>
    </div>
  </>
}

export default BigButton
